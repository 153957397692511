<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card no-body class="faq-search" :style="{ backgroundImage: `url(${require('@/assets/images/banner/banner.png')})` }">
        <b-card-body class="text-center">
          <h2 class="text-primary">Seja bem-vindo ao FAQ do ConsigPrev</h2>
          <b-card-text class="mb-2"> Aqui vamos tentar responder suas perguntas </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                @input="fetchData"
                v-model="pesquisar.descricao"
                placeholder="Digite palavras-chave chaves sobre sua dúvida aqui."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->
    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
        v-for="(categoria, index) in faqCat"
        :key="index"
      >
        <b-tab>
          <template #title>
            <span class="font-weight-bold"> Categoria: {{ categoria }} </span>
          </template>
          <app-collapse id="faq-payment-qna" accordion type="margin" class="mt-2">
            <app-collapse-item v-for="(item, id) in faqData" :key="id" :title="item.pergunta">
              {{ item.resposta }}
            </app-collapse-item>
          </app-collapse>
        </b-tab>
      </b-tabs>
    </section>

    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <b-card class="shadow-none faq-contact-card">
            <h2>Ainda tem dúvidas?</h2>
            <b-card-text class="mb-3"> Se não encontrou a resposta em nosso FAQ, você pode nos contactar! </b-card-text>
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>ajuda@consigprev.com.br</h4>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
  import {
    BCard,
    BCardBody,
    BForm,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BRow,
    BCol,
    BAvatar,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

  export default {
    components: {
      BForm,
      BCard,
      BRow,
      BCol,
      BAvatar,
      BCardBody,
      BInputGroup,
      BFormInput,
      BCardText,
      BInputGroupPrepend,
      BTabs,
      BTab,
      BImg,
      AppCollapse,
      AppCollapseItem,
    },
    data() {
      return {
        faqSearchQuery: '',
        faqData: {},
        faqCat: {},
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 25, currentPage: 1 },
      }
    },
    mounted() {
      this.carregarGrid()
    },
    watch: {
      faqSearchQuery: {
        immediate: true,
        handler() {
          this.fetchData()
        },
      },
    },
    methods: {
      carregarGrid() {
        useJwt
          .pesquisar('utils/Getfaq', this.pesquisar)
          .then((response) => {
            response.data.dados.filter((f) => {
              this.faqCat.categoria = f.categoria.categoria
            })
            this.faqData = response.data.dados
            this.totalRows = response.data.total
          })
          .catch((error) => {
            console.error(error)
          })
      },
      fetchData(item) {
        useJwt
          .pesquisar('utils/Getfaq', this.pesquisar)
          .then((response) => {
            response.data.dados.filter((f) => {
              this.faqCat.categoria = f.categoria.categoria
            })
            this.faqData = response.data.dados
            this.totalRows = response.data.total
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-faq.scss';
</style>
