<template>
  <b-card title="Versão do sistema ">
    <b-card-text> Versão atual: {{ appVersion }} </b-card-text>
  </b-card>
</template>

<script>
  export default {
    computed: {
      appVersion() {
        return process.env.VUE_APP_VERSION
      },
    },
  }
</script>

<style>
  /* Made By:
  - Developers: Leander Alves, Agrício Ribeiro, Hugo Alves
  - Planning: Alaide Silva, Eder Franca e Vinicius Ribeiro */
</style>
