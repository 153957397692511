<template>
  <div>
    <b-tabs>
      <!--Notas de versão-->
      <b-tab>
        <template #title>
          <feather-icon icon="BookmarkIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Notas de versão</span>
        </template>
        <notasVersao />
      </b-tab>

      <!--Manuais do sistema-->
      <b-tab active>
        <template #title>
          <feather-icon icon="BookIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Manuais do sistema</span>
        </template>
        <manuais />
      </b-tab>

      <!--FAQ - Peguntas Frequentes-->
      <b-tab>
        <template #title>
          <feather-icon icon="HelpCircleIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">FAQ - Peguntas frequentes</span>
        </template>
        <faq />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import { BTabs, BTab } from 'bootstrap-vue'
  import notasVersao from './centralapoioNotasVersao.vue'
  import manuais from './centralapoioManuais.vue'
  import faq from './caFaq.vue'

  export default {
    components: {
      BTabs,
      BTab,
      notasVersao,
      manuais,
      faq,
    },
    data() {
      return {
        options: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
  }
</script>
