<template>
  <b-card title="Manual do sistema">
    <b-card-text
      >O manual do sistema encontra-se
      <a href="#" @click="downloadManual()" title="Abrir documento"> clicando aqui</a>
    </b-card-text>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {}
    },
    methods: {
      downloadManual() {
        window.open('https://s3.amazonaws.com/homologacao.consigprev.com.br/help/ManualUsuario1v.pdf')
      },
    },
  }
</script>

<style></style>
